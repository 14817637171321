import { BsFacebook, BsInstagram, BsYoutube, BsLinkedin } from "react-icons/bs"
import { FaTiktok } from "react-icons/fa"
import Link from "next/link"
import { useContext, useState } from "react"
import { useIsomorphicLayoutEffect } from "@react-spring/web"
import { useRouter } from "next/router"
import { CookiePolicyContext } from "../pages/_app"

function shuffle(array) {
  let currentIndex = array.length

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

const AREAS = shuffle([
  // "Coalville",
  "Mountsorrel",
  "East Leake",
  "Loughborough",
  "Quorn",
  "Sileby",
  "Shepshed",
  "Barrow Upon Soar",
  "Rothley",
  "Woodhouse Eaves",
  "Wymeswold",
  "Long Whatton",
  "Hathern",
  "West Bridgford",
])

function Footer() {
  const { setShowCookiePolicy } = useContext(CookiePolicyContext)

  const [areas, setAreas] = useState(AREAS.slice(0, 4))
  const router = useRouter()
  const currentPath = router.pathname
  const areaPossiblePath = currentPath.slice(1).replaceAll("-", " ").toLowerCase()

  const allowedAreas = AREAS.filter(area => area.toLowerCase() !== areaPossiblePath)

  useIsomorphicLayoutEffect(() => {
    setAreas(shuffle(AREAS).slice(0, 4))
  }, [])

  return (
    <div className="relative z-20 overflow-hidden bg-teal-900 max-w-screen print:bg-white">
      <div className="absolute top-0 left-0 flex flex-col opacity-0 pointer-events-none gap-y-4">
        <Link
          aria-label="Properties for rent in Loughborough"
          href="/loughborough"
          className="text-sm transition-all duration-300 hover:text-white"
          prefetch={false}
        >
          Properties for rent in Loughborough
        </Link>
        <Link
          aria-label="Properties for rent in West Bridgford"
          href="/west-bridgford"
          className="text-sm transition-all duration-300 hover:text-white"
          prefetch={false}
        >
          Properties for rent in West Bridgford
        </Link>
        <Link
          aria-label="Properties for rent in Quorn"
          href="/quorn"
          className="text-sm transition-all duration-300 hover:text-white"
          prefetch={false}
        >
          Properties for rent in Quorn
        </Link>
        <Link
          aria-label="Properties for rent in East Leake"
          href="/east-leake"
          className="text-sm transition-all duration-300 hover:text-white"
          prefetch={false}
        >
          Properties for rent in East Leake
        </Link>
        <Link
          aria-label="Properties for rent in Mountsorrel"
          href="/mountsorrel"
          className="text-sm transition-all duration-300 hover:text-white"
          prefetch={false}
        >
          Properties for rent in Mountsorrel
        </Link>
        <Link
          aria-label="Properties for rent in Sileby"
          href="/sileby"
          className="text-sm transition-all duration-300 hover:text-white"
          prefetch={false}
        >
          Properties for rent in Sileby
        </Link>
      </div>
      <div className="px-4 py-12">
        <div className="mx-auto gap-x-4 lg:gap-x-8 lg:flex sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
          <div className="flex flex-col justify-between w-full lg:w-96">
            <div>
              <img alt="logo" className="h-8 select-none" src="/images/logo.png" />
              <p className="mt-3 text-sm text-cpink print:text-black">
                We are here to provide a straightforward, ethical and innovative lettings service in a professional environment
                with a committed and caring team.
              </p>
              <h1 className="mt-4 text-sm font-medium text-cpink print:text-black">Our Office</h1>
              <div>
                <Link
                  aria-label="7 Biggin Street, Loughborough, LE11 1UA"
                  className="inline-block"
                  href="https://www.google.com/maps/place/Huntleys/@52.7729544,-1.2065073,17z/data=!3m1!4b1!4m6!3m5!1s0x4879e0b0402cece5:0x8d76f739b0f7dc88!8m2!3d52.7729544!4d-1.2065073!16s%2Fg%2F11bzzwh_xm"
                  target="_blank"
                  prefetch={false}
                >
                  <p className="mt-1 text-sm text-cpink print:text-black">
                    7 Biggin Street
                    <br />
                    Loughborough
                    <br />
                    LE11 1UA
                  </p>
                </Link>
              </div>
              <div className="flex mt-4 gap-x-8 md:gap-x-0">
                {/* <div className="flex-1">
                  <h1 className="text-sm font-medium text-cpink print:text-black">Sales</h1>
                  <p className="mt-1 text-sm text-cpink print:text-black">
                    01509 320 153
                    <br />
                    hello@huntleys.net
                  </p>
                </div> */}
                <div className="flex-1">
                  {/* <h1 className="text-sm font-medium text-cpink print:text-black">Lettings</h1> */}
                  <p className="mt-1 text-sm text-cpink print:text-black">
                    01509 320 320
                    <br />
                    let@huntleys.net
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex gap-x-10">
                <Link
                  aria-label="Facebook"
                  href="https://facebook.com/Huntleys.EA"
                  target="_blank"
                  prefetch={false}
                  className="duration-300 transiiton-all hover:text-white text-cpink"
                >
                  <BsFacebook className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 text-cpink transition-all duration-300 hover:text-white" />
                </Link>
                <Link
                  aria-label="Instagram"
                  href="https://instagram.com/huntleys_ea"
                  target="_blank"
                  prefetch={false}
                  className="duration-300 transiiton-all hover:text-white text-cpink"
                >
                  <BsInstagram className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 text-cpink transition-all duration-300 hover:text-white" />
                </Link>
                <Link href="https://tiktok.com/@huntleys.EA" target="_blank" aria-label="TikTok">
                  <FaTiktok className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 text-cpink transition-all duration-300 hover:text-white" />
                </Link>
                <Link aria-label="YouTube" href="https://www.youtube.com/@Huntleys-EA" target="_blank" prefetch={false}>
                  <BsYoutube className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 text-cpink transition-all duration-300 hover:text-white" />
                </Link>
                <Link
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/company/10636175/"
                  target="_blank"
                  prefetch={false}
                  className="duration-300 transiiton-all hover:text-white text-cpink"
                >
                  <BsLinkedin className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 text-cpink transition-all duration-300 hover:text-white" />
                </Link>
                <Link
                  aria-label="X / Twitter"
                  href="https://twitter.com/HuntleysLet"
                  target="_blank"
                  prefetch={false}
                  className="duration-300 transiiton-all hover:text-white text-cpink"
                >
                  {/* <BsTwitter className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 text-cpink transition-all duration-300 hover:text-white" /> */}
                  <svg
                    className="w-6 h-6 print:text-black sm:h-6.5 sm:w-6.5 transition-all duration-300"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1002"
                    height="937"
                    fill="currentColor"
                    viewBox="0 0 1002 937"
                  >
                    <path d="M3.33.251l386.39 516.64L.89 936.941H88.4l340.42-367.76 275.05 367.76h297.8l-408.13-545.7L955.46.251h-87.51l-313.51 338.7L301.13.251H3.33zm128.69 64.46h136.81l604.13 807.76H736.15L132.02 64.711z"></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
          <div className="grid flex-col flex-1 grid-cols-2 mt-8 text-cpink print:text-black lg:mt-0 xl:hidden gap-y-8 gap-x-8">
            {/* <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
              <h1 className="font-medium">BUY</h1>
              <div>
                <Link
                  aria-label="Properties for Sale"
                  href="/buy"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Properties for Sale
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Property Alerts"
                  href="/alerts"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Property Alerts
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Mortgages"
                  href="https://www.mortgageadvicebureau.com/mortgage-advisers/quorn/huntleys-estate-agents"
                  target="_blank"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                  prefetch={false}
                >
                  Mortgages
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link>
              </div>
            </div> */}
            {/* <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
              <h1 className="font-medium">SELL</h1>
              <div>
                <Link
                  aria-label="Sales Service"
                  href="/sell"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Sales Service
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Arrange a Valuation"
                  href="/valuation"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Arrange a Valuation
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Instant Property Report"
                  href="https://insights.street.co.uk/l/75b648ae-f226-4cee-bd9b-3ed5fa457c76/property-search"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                  target="_blank"
                  prefetch={false}
                >
                  Instant Property Report
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link>
              </div>
            </div> */}
            <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
              <h1 className="font-medium">RENT</h1>
              {/* <div>
                <Link
                  aria-label="Search"
                  className="text-sm transition-all duration-300 hover:text-white"
                  // onClick={() => {
                  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  //   setTimeout(() => setIsSearchOpen(true), 875)
                  // }}
                  href="/"
                  scroll={false}
                >
                  Search
                </Link>
              </div> */}
              <div>
                <Link
                  aria-label="Properties for Rent"
                  href="/rent"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Properties for Rent
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Arrange a viewing"
                  href="/viewing?type=lettings"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Arrange a viewing
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Apply Online"
                  href="https://huntleys.typeform.com/to/l3cj1d6T?typeform-source=www.huntleys.net"
                  target="_blank"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                  prefetch={false}
                >
                  Apply Online
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
              <h1 className="font-medium">LANDLORDS</h1>
              <div>
                <Link
                  aria-label="Letting Services"
                  href="/landlords#services"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Letting Services
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Arrange a Valuation"
                  href="/valuation"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Arrange a Valuation
                </Link>
              </div>
              {/* <div>
                <Link aria-label="Frequently Asked Questions" href="/landlords#faqs" className="text-sm transition-all duration-300 hover:text-white">
                  Frequently Asked Questions
                </Link>
              </div> */}
              {/* <div>
                <Link
                  aria-label="Agency Agreement"
                  href="/docs/agency-agreement.pdf"
                  target="_blank"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                >
                  Agency Agreement
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link>
              </div> */}
              <div>
                {/* <Link
                  aria-label="Legionella Risk Assessment"
                  href="/docs/risk-assessment.pdf"
                  target="_blank"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                >
                  Legionella Risk Assessment
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link> */}
                <Link
                  aria-label="HMO Guidance"
                  href="/hmo"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                  prefetch={false}
                >
                  HMO Guidance
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Yield Calculator"
                  href="/rental-yield-calculator"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                  prefetch={false}
                >
                  Yield Calculator
                </Link>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">AREAS</h1>
                {areas.map((area, index) => (
                  <div key={index}>
                    <Link
                      aria-label="Find area"
                      className="text-sm transition-all duration-300 hover:text-white"
                      onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: "instant" })
                      }}
                      href={`/${area.replaceAll(" ", "-").toLowerCase()}`}
                      prefetch={false}
                    >
                      {area}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
              <h1 className="font-medium">RESOURCES</h1>
              <div>
                <Link
                  aria-label="Articles"
                  href="/articles"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Articles
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Frequently Asked Questions"
                  className="text-sm transition-all duration-300 hover:text-white"
                  href="/faqs"
                  prefetch={false}
                >
                  Frequently Asked Questions
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Press Kit"
                  href="/press"
                  className="text-sm transition-all duration-300 hover:text-white"
                  prefetch={false}
                >
                  Press Kit
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between flex-1 gap-8 xl:ml-4 md:hidden xl:flex">
            <div className="flex-1 hidden gap-8 h-96 md-gap-8 xl:flex text-cpink">
              {/* <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">BUY</h1>
                <div>
                  <Link
                    aria-label="Properties for Sale"
                    href="/buy"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Properties for Sale
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Property Alerts"
                    href="/alerts"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Property Alerts
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Mortgages"
                    href="https://www.mortgageadvicebureau.com/mortgage-advisers/quorn/huntleys-estate-agents"
                    target="_blank"
                    className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                    prefetch={false}
                  >
                    Mortgages
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3.5 h-3.5 "
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                    </svg>
                  </Link>
                </div>
              </div> */}
              {/* <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">SELL</h1>
                <div>
                  <Link
                    aria-label="Sales Service"
                    href="/sell"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Sales Service
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Arrange a Valuation"
                    href="/valuation?type=sell"
                    className="text-sm transition-all duration-300 hover:text-white"
                  >
                    Arrange a Valuation
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Instant Property Report"
                    href="https://insights.street.co.uk/l/75b648ae-f226-4cee-bd9b-3ed5fa457c76/property-search"
                    className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                    target="_blank"
                    prefetch={false}
                  >
                    Instant Property Report
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3.5 h-3.5 "
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                    </svg>
                  </Link>
                </div>
              </div> */}
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">RENT</h1>
                {/* <div>
                  <Link
                    aria-label="Search"
                    className="text-sm transition-all duration-300 hover:text-white"
                    // onClick={() => {
                    //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                    //   setTimeout(() => setIsSearchOpen(true), 875)
                    // }}
                    href="/"
                    scroll={false}
                  >
                    Search
                  </Link>
                </div> */}
                <div>
                  <Link
                    aria-label="Properties for Rent"
                    href="/rent"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Properties for Rent
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Arrange a viewing"
                    href="/viewing?type=lettings"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Arrange a viewing
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Apply Online"
                    href="https://huntleys.typeform.com/to/l3cj1d6T?typeform-source=www.huntleys.net"
                    target="_blank"
                    className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                    prefetch={false}
                  >
                    Apply Online
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3.5 h-3.5 "
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">LANDLORDS</h1>
                <div>
                  <Link
                    aria-label="Landlord Services"
                    href="/landlords#services"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Letting Services
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Arrange a Valuation"
                    href="/valuation?type=let"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Arrange a Valuation
                  </Link>
                </div>
                {/* <div>
                  <Link aria-label="Frequently Asked Questions" href="/landlords#faqs" className="text-sm transition-all duration-300 hover:text-white">
                    Frequently Asked Questions
                  </Link>
                </div> */}
                {/* <div>
                <Link
                  aria-label="Agency Agreement"
                  href="/docs/agency-agreement.pdf"
                  target="_blank"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                >
                  Agency Agreement
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link>
              </div> */}
                <div>
                  {/* <Link
                  aria-label="Legionella Risk Assessment"
                  href="/docs/risk-assessment.pdf"
                  target="_blank"
                  className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                >
                  Legionella Risk Assessment
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3.5 h-3.5 "
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </Link> */}
                  <Link
                    aria-label="HMO Guidance"
                    href="/hmo"
                    className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                    prefetch={false}
                  >
                    HMO Guidance
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Yield Calculator"
                    href="/rental-yield-calculator"
                    className="inline-flex items-center text-sm transition-all duration-300 gap-x-2 hover:text-white"
                    prefetch={false}
                  >
                    Yield Calculator
                  </Link>
                </div>
              </div>
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">AREAS</h1>
                {areas.map((area, index) => (
                  <div key={index}>
                    <Link
                      aria-label="Find area"
                      className="text-sm transition-all duration-300 hover:text-white"
                      // onClick={() => {
                      //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                      //   setTimeout(() => setIsSearchOpen(true), 875)
                      // }}
                      href={`/${area.replaceAll(" ", "-").toLowerCase()}`}
                      scroll={false}
                      prefetch={false}
                    >
                      {area}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">RESOURCES</h1>
                <div>
                  <Link
                    aria-label="Articles"
                    href="/articles"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Articles
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="FAQs"
                    className="text-sm transition-all duration-300 hover:text-white"
                    href="/faqs"
                    scroll={false}
                    prefetch={false}
                  >
                    Frequently Asked Questions
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Press Kit"
                    href="/press"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Press Kit
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="hidden gap-8 mt-8 md:flex md:mt-0 md:gap-4 text-cpink">
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">AREAS</h1>
                {areas.map((area, index) => (
                  <div key={index}>
                    <Link
                      aria-label="Search"
                      className="text-sm transition-all duration-300 hover:text-white"
                      // onClick={() => {
                      //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                      //   setTimeout(() => setIsSearchOpen(true), 875)
                      // }}
                      href={`/${area.replaceAll(" ", "-").toLowerCase()}`}
                      scroll={false}
                      prefetch={false}
                    >
                      {area}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="flex flex-col flex-1 pt-5 border-t border-cpink gap-y-4">
                <h1 className="font-medium">RESOURCES</h1>
                <div>
                  <Link
                    aria-label="Articles"
                    href="/articles"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Articles
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="FAQs"
                    className="text-sm transition-all duration-300 hover:text-white"
                    href="/faqs"
                    scroll={false}
                    prefetch={false}
                  >
                    Frequently Asked Questions
                  </Link>
                </div>
                <div>
                  <Link
                    aria-label="Press Kit"
                    href="/press"
                    className="text-sm transition-all duration-300 hover:text-white"
                    prefetch={false}
                  >
                    Press Kit
                  </Link>
                </div>
              </div>
              <div className="flex-1 hidden xl:block" />
              <div className="flex-1 hidden xl:block" />
            </div> */}
          </div>
        </div>
        <div className="flex flex-col-reverse items-start justify-between mx-auto mt-10 lg:items-end lg:flex-row gap-x-4 lg:gap-x-8 sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
          <div className="flex items-center mt-6 text-cpink print:text-black lg:mt-0 gap-x-4">
            <div>
              <Link
                aria-label="Privacy Policy"
                href="/docs/privacy.pdf"
                className="text-sm transition-all duration-300 sm:hidden hover:text-white"
                target="_blank"
                prefetch={false}
              >
                Privacy
              </Link>
              <Link
                aria-label="Privacy Policy"
                href="/docs/privacy.pdf"
                className="hidden text-sm transition-all duration-300 sm:block hover:text-white"
                target="_blank"
                prefetch={false}
              >
                Privacy Policy
              </Link>
            </div>
            <p className="text-sm transition-all duration-300 hover:text-white">•</p>
            <div>
              <p
                aria-label="Privacy Policy"
                className="text-sm transition-all duration-300 cursor-pointer hover:text-white"
                target="_blank"
                prefetch={false}
                onClick={e => {
                  setShowCookiePolicy(true)
                  document?.body?.classList?.add("overflow-hidden")
                }}
              >
                Cookies
              </p>
            </div>
            <p className="text-sm transition-all duration-300 hover:text-white">•</p>
            <div>
              <Link
                aria-label="Complaints Procedure"
                href="/docs/complaints-procedure.pdf"
                target="_blank"
                className="text-sm transition-all duration-300 hover:text-white"
                prefetch={false}
              >
                Complaints
              </Link>
            </div>
            <p className="text-sm transition-all duration-300 hover:text-white">•</p>
            <div>
              <Link
                aria-label="CMP"
                href="/docs/client-money-protection.pdf"
                className="text-sm transition-all duration-300 sm:hidden hover:text-white"
                target="_blank"
                prefetch={false}
              >
                CMP
              </Link>
              <Link
                aria-label="CMP Certificate"
                href="/docs/client-money-protection.pdf"
                className="hidden text-sm transition-all duration-300 sm:block hover:text-white"
                target="_blank"
                prefetch={false}
              >
                CMP Certificate
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-between w-full select-none sm:w-fit sm:gap-x-8">
            <img loading="lazy" alt="TPO" className="h-8" src="/images/tpo.svg" />
            <img loading="lazy" alt="TSI" className="h-8" src="/images/tsi.svg" />
            <img loading="lazy" alt="ARLA" className="h-8" src="/images/arla.png" />
            <img loading="lazy" alt="DPS" className="h-8" src="/images/dps.png" />
          </div>
        </div>
      </div>
      <div className="h-12 sm:hidden"></div>
    </div>
  )
}

export default Footer

