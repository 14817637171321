import validator from "validator"

function SearchInput({
  isSearchOpen,
  searchQuery,
  setIsSearchOpen,
  searchInputRef,
  setSearchQuery,
  canClose = true,
  hasBorder = false,
}) {
  return (
    <div
      className={`sticky top-0 flex overflow-hidden items-center w-full p-4 bg-white gap-x-3  ${
        hasBorder
          ? `${
              isSearchOpen
                ? "border-b border-stone-900 text-stone-900"
                : "border-white text-stone-900"
            }`
          : ""
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path
          fillRule="evenodd"
          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
          clipRule="evenodd"
        />
      </svg>
      <input
        className={[
          `flex-1 bg-transparent outline-none caret-teal-900 hover:cursor-text overflow-hidden ${
            isSearchOpen ? "text-stone-900" : "text-stone-900"
          }`,
        ]}
        onChange={event => setSearchQuery(event.target.value)}
        placeholder="Search for properties by area or address"
        ref={searchInputRef}
        value={searchQuery}
      />
      {isSearchOpen && (
        <div className="flex items-center justify-center -mr-1.5 gap-x-2">
          {!validator.isEmpty(searchQuery) && (
            <div className="flex items-center justify-center gap-x-2">
              <button
                className="pointer-events-auto"
                onClick={() => setSearchQuery("")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </button>
              <div className="w-px h-5 bg-stone-300" />
            </div>
          )}
          <button
            className="pointer-events-auto"
            onClick={event => {
              event.stopPropagation()
              setIsSearchOpen(false)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  )
}

export default SearchInput
